<template>
  <!-- 小规模增值税及附加税费申报表-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>

      <table class="content" cellspacing="0">
        <tr>
          <td colspan="2" class="center top_bg weight">扣除后不含税销售额(其中1%征收率)</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list.a17" @change="changeList()"></qzf-str-td>
        </tr>
        <tr class="top_bg">
          <td colspan="4" class="center weight">应税行为（3%征收率）扣除额计算</td>
        </tr>
        <tr class="center bg_color">
          <td style="width:25%">期初余额</td>
          <td style="width:25%">本期发生额</td>
          <td style="width:25%">本期扣除额</td>
          <td style="width:25%">期末余额</td>
        </tr>
        <tr class="center bg_color">
          <td>1</td>
          <td>2</td>
          <td>3（3≤1+2之和，且3≤5）</td>
          <td>4=1+2-3</td>
        </tr>
        <tr>
          <qzf-str-td :disabled="false" v-model:amount="list.a1" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list.a2"  @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list.a3"  @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a4" @change="changeList()"></qzf-str-td>
        </tr>

        <tr class="top_bg">
          <td colspan="4" class="center weight">应税行为（3%征收率）计税销售额计算</td>
        </tr>
        <tr class="center bg_color">
          <td>全部含税收入（适用3%征收率）</td>
          <td>本期扣除额</td>
          <td>含税销售额</td>
          <td>不含税销售额</td>
        </tr>
        <tr class="center bg_color">
          <td>5</td>
          <td>6=3</td>
          <td>7=5-6</td>
          <td>8=7÷(1+征收率)</td>
        </tr>
        <tr>
          <qzf-str-td :disabled="false" v-model:amount="list.a5" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a6"  @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a7" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a8"  @change="changeList()"></qzf-str-td>
        </tr>

        <tr class="top_bg">
          <td colspan="4" class="center weight">应税行为（5%征收率）扣除额计算</td>
        </tr>
        <tr class="center bg_color">
          <td>期初余额</td>
          <td>本期发生额</td>
          <td>本期扣除额</td>
          <td>期末余额</td>
        </tr>
        <tr class="center bg_color">
          <td>9</td>
          <td>10</td>
          <td>11（11≤9+10之和，且11≤13）</td>
          <td>12=9+10-11</td>
        </tr>
        <tr>
          <qzf-str-td :disabled="false" v-model:amount="list.a9" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list.a10" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list.a11" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a12" @change="changeList()"></qzf-str-td>
        </tr>

        <tr class="top_bg">
          <td colspan="4" class="center weight">应税行为（5%征收率）计税销售额计算</td>
        </tr>
        <tr class="center bg_color">
          <td>全部含税收入（适用5%征收率）</td>
          <td>本期扣除额</td>
          <td>含税销售额</td>
          <td>不含税销售额</td>
        </tr>
        <tr class="center bg_color">
          <td>13</td>
          <td>14=11</td>
          <td>15=13-14</td>
          <td>16=15÷1.05</td>
        </tr>
        <tr>
          <qzf-str-td :disabled="false" v-model:amount="list.a13" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a14" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a15" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list.a16"  @change="changeList()"></qzf-str-td>
        </tr>
        
      </table>
    </div>

    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button @click="saveFb1" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveFb1" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb1',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      list: {},
      taxNo: '',
      comName: '',
      durationOfTaxTime:''
    }
  },
  created() {
    
  },
  methods: {
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list.a4 = String((this.list.a1*1 + this.list.a2*1 - this.list.a3*1).toFixed(2))
      this.list.a6 = this.list.a3
      this.list.a7 = String((this.list.a5*1 - this.list.a6*1).toFixed(2))
      this.list.a8 = String((this.list.a7*1 / 1.03).toFixed(2))
      this.list.a12 = String((this.list.a9*1 + this.list.a10*1 - this.list.a11*1).toFixed(2))
      this.list.a14 = this.list.a12
      this.list.a15 = String((this.list.a13*1 - this.list.a14*1).toFixed(2))
      this.list.a16 = String((this.list.a15*1 / 1.05).toFixed(2))
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb1',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
          if(res.data.data.info){
            this.list = res.data.data.info      
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'fb1'}).then(res=>{
          this.durationOfTaxTime = res.data.data.time
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
      
    },
    saveFb1(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb1',
          items:[this.list],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb1',
          items:[this.list],
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }

      
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
