<template>
  <div style="position: relative;">
    <div class="right_box" v-if="!this.paramCheck.comId">
      <el-button size="small" @click="printingNew()" type="success">打印</el-button>
      <el-button type="primary" @click="download()" size="small" plain>导出</el-button>
    </div>
    <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="消费税">
      <xfsMain title="消费税" ref="xfsMain" :startAccountPeriod="startAccountPeriod" :comId="comId"></xfsMain>
    </el-tab-pane>
    <el-tab-pane label="消费税附加资料（一）">
      <xfsFb1 title="消费税附加资料（一）" ref="xfsFb1" :startAccountPeriod="startAccountPeriod" :comId="comId"></xfsFb1>
    </el-tab-pane>
    <el-tab-pane label="本期减（免）税额明细表">
      <xfsJmsemx title="本期减（免）税额明细表" ref="xfsJmsemx" :startAccountPeriod="startAccountPeriod" :comId="comId"></xfsJmsemx>
    </el-tab-pane>
    <el-tab-pane label="附加税">
      <xfsFjs title="附加税" ref="xfsFjs" :startAccountPeriod="startAccountPeriod" :comId="comId"></xfsFjs>
    </el-tab-pane>
  </el-tabs>
  </div>
 
</template>

<script>
import xfsMain from "./xfsMain.vue"
import xfsFb1 from "./xfsFb1.vue"
import xfsJmsemx from "./xfsJmsemx.vue"
import xfsFjs from "./xfsFjs.vue"
import { report ,exportReport} from "@/api/printSet"

export default {
  name:'xfs',
  components:{
    xfsMain,
    xfsFb1,
    xfsJmsemx,
    xfsFjs
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  created(){
    if(this.paramCheck.comId){
      // //console.log("查看报表");
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }
  },
  methods:{
    handleClick(tab){
      //console.log(tab);
      if(tab.props.label == '消费税'){
        this.$refs.xfsMain.getList()
      }else if(tab.props.label == '消费税附加资料（一）'){
        this.$refs.xfsFb1.getList()
      } else if(tab.props.label == '本期减（免）税额明细表'){
        this.$refs.xfsJmsemx.getList()
      }else if(tab.props.label == '附加税'){
        this.$refs.xfsFjs.getList()
      }
    },
    download(){
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_xfs']
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    printingNew(){
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_xfs']
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 99;
}
</style>