<template>
  <!-- 小规模增值税及附加税费申报表-->
  <div>
    <div class="big_box4">
      <!-- <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div> -->
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <el-scrollbar :height="contentStyleObj">
        <table class="content" cellspacing="0">
          <tr class="top_bg">
            <td colspan="2" class="weight">服务、不动产和无形资产（3%征收率）</td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:50%;">增值税专用发票不含税销售额</td>
            <qzf-str-td v-model:amount="list.t1" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="list.t2" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">开具其他发票及未开票销售额</td>
            <qzf-str-td v-model:amount="list.t3" @change="changeList()"></qzf-str-td>
          </tr>

          <tr class="top_bg">
            <td colspan="2" class="weight">服务、不动产和无形资产（5%征收率）</td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">增值税专用发票不含税销售额</td>
            <qzf-str-td v-model:amount="list.t4" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中：销售不动产</td>
            <qzf-str-td v-model:amount="list.t5" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="list.t6" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中：销售不动产</td>
            <qzf-str-td v-model:amount="list.t7" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中：向个人出租住房</td>
            <qzf-str-td v-model:amount="list.t8" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">开具其他发票及未开票销售额</td>
            <qzf-str-td v-model:amount="list.t9" @change="changeList()"></qzf-str-td>
          </tr>

          <tr class="top_bg">
            <td colspan="2" class="weight">服务、不动产和无形资产（3%及5%征收率）</td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其他免税销售额（含未开票）</td>
            <qzf-str-td v-model:amount="list.t10" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">出口免税销售额（含未开票）</td>
            <qzf-str-td v-model:amount="list.t11" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中：其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="list.t12" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td style="width:12%;" class="bg_color">当期合计不含税销售额（除销售不动产）</td>
            <qzf-str-td v-model:amount="list.t13" @change="changeList()"></qzf-str-td>
          </tr>
          <!-- 货物及劳务 -->
          <tr class="top_bg">
            <td colspan="2" class="weight">货物及劳务</td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:50%;">增值税专用发票不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t1" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t2" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">开具其他发票及未开票销售额</td>
            <qzf-str-td v-model:amount="hwList.t3" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">销售使用过的固定资产不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t4" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中: 其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t5" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其他免税销售额(含未开票)</td>
            <qzf-str-td v-model:amount="hwList.t6" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">出口免税销售额(含未开票)</td>
            <qzf-str-td v-model:amount="hwList.t7" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:12%;">其中: 其他增值税发票不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t8" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td  style="width:12%;" class="bg_color">当期合计不含税销售额</td>
            <qzf-str-td v-model:amount="hwList.t9" @change="changeList()"></qzf-str-td>
          </tr>
        </table>
      </el-scrollbar>
     
    </div>

    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button @click="saveValues" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveValues" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb2',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      list:[{},{},{}],
      taxNo: '',
      comName: '',
      durationOfTaxTime:'',
      hwList:{},
      contentStyleObj:{}
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(284)
    
  },
  methods: {
    // 公式
    changeList(){
      // if(!this.expStatus){
      //   return
      // }
      // this.list.map(v=>{
      //   v.bqynse = String(((v.zzsse*1 + v.zzsmdse*1 - v.ldtsbqkce*1) * v.sl).toFixed(2))
      //   v.jze =  String(((v.bqynse*1 - v.jmse*1) * v.jzbl).toFixed(2))
      //   v.bqybtse = String((v.bqynse*1 - v.jmse*1 - v.jze*1 - v.bqyjse*1).toFixed(2))
      // })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb4',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
        let param1 = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb6',
          comId:this.comId
        }
        gsInfo(param1).then(res=>{
          if(res.data.data.info){
            this.hwList = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'fb4'}).then(res=>{
          if(res.data.data.info){
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
        gsInfo({tableName: 'gs_small_vat',itemName:'fb6'}).then(res=>{
          if(res.data.data.info){
            this.hwList = res.data.data.info
          }
        })
      }

      
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb4',
          items:[this.list],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let param1 = {
          tableName:'gs_small_vat',
          itemName:'fb6',
          items:[this.hwList],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res => {
          this.saveHwInfo(param1)
          if(res.data.msg == "success") {
            this.getInfo()
          }
        })
      }else{
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb4',
          items:[this.list]
        }
        let param1 = {
          tableName:'gs_small_vat',
          itemName:'fb6',
          items:[this.hwList]
        }
        gsEdit(param).then(res => {
          this.saveHwInfo(param1)
          if(res.data.msg == "success") {
            this.getInfo()
          }
        })
      }
    },
    saveHwInfo(param){
      gsEdit(param).then(res => {
        if(res.data.msg == "success") {
          this.$qzfMessage("保存成功")
          this.getHwInfo()
        }
      })
    },
    getInfo(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb4',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
          if(res.data.data.info){
            this.list = res.data.data.info    
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'fb4'}).then(res=>{
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
    },
    getHwInfo(){
      if(this.startAccountPeriod){
        let param1 = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb6',
          comId:this.comId
        }
        gsInfo(param1).then(res=>{
          if(res.data.data.info){
            this.hwList = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'fb6'}).then(res=>{
          if(res.data.data.info){
            this.hwList = res.data.data.info
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.content{
  input{
    width: 100%;
    line-height:25px;
    padding: 0 6px;
    font-size: 13px;
  }
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
